
























 import { Page, Sort, Filter, Edit, Toolbar } from "@syncfusion/ej2-vue-grids";
import { Component, Vue } from 'vue-property-decorator'
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import {MyGridOption} from '@/Helper/MyDataAdapter';
import AddCodeSales from "./Component/AddCode.vue";
import { DiscountType, Enums } from "@/Helper/Enum";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { DataManager } from "@syncfusion/ej2-data";
import {createElement} from "@syncfusion/ej2-base";
Vue.use(GridPlugin);

let dropInstance:any = null;
@Component({
  components:{

  },
     provide:{
     grid: [Page, Sort, Filter, Edit, Toolbar]
  }

})
export default class Promocode extends Vue {
      dataManager = MyGridOption.getDataManager('SalesPromocodeList');
    filterOptions = {    type: 'Menu'};
    wrapSettings= { wrapMode: 'Both ' };
    RequiredAtr= { required: true };
    DateAtr= { required: true,date:true };
    editSettings= { allowAdding: true,  mode: 'Dialog',  template: function() {
          return { template: AddCodeSales };
        } };
    toolbar= [ 'Add'];
     filter= {
        ui: {
            create: function (args:any) {
               
                let flValInput = createElement('input', { className: 'flm-input' });
                args.target.appendChild(flValInput);
                dropInstance = new DropDownList({
                dataSource: new DataManager(Enums.DiscountType),
                fields: { text: 'data', value: 'value' },
                placeholder: 'Select a value',
                popupHeight: '200px'
            });
            dropInstance.appendTo(flValInput);
            },
            write: function (args:any) {
                dropInstance.value = args.filteredValue;
            },
            read: function (args:any) {
                args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstance.value);
            }
        }
    }
    actionFailure(args:any) {
      console.log(args);
      this.$store.commit("ChangeLoading", false);
      var t = args.error[0].error.response;
      
       this.$bus.$emit('testing_call',{data:t});
}

actionBegin(args:any){
  if(args.requestType == "save" && args.action=="add"){
this.$store.commit("ChangeLoading", true);
  }
}
actionComplete(args:any){
  if(args.requestType == "save" && args.action=="add"){
    this.$store.commit("ChangeLoading", false);
  }
}
DiscountType(field:any, data:any, column:any){
  return DiscountType[data[field]] ;
}

}
